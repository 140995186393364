import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import Swal from 'sweetalert2';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-inicio',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RecaptchaModule,
    MatIconModule,
  ],
  templateUrl: './inicio.component.html',
  styleUrl: './inicio.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InicioComponent {
    constructor() {
 
  }
}