<body>
  <div class="login-register" style="background-image: url(/assets/images/background.jpg); position: relative;">
    <!-- Imagen en la esquina superior izquierda -->
    <img class="logo-ayuntamiento" src="/assets/images/logo-ayuntamiento.svg" alt="Ayuntamiento" />

    <div class="login-register-box">
      <mat-card>
        <mat-card-content>
          <!-- Contenido principal -->
          <div style="text-align: center; margin: 30px 0;">
            <mat-icon style="font-size: 50px; color: #009fe1; height: 50px; width: 50px;">construction</mat-icon>
            <h1 style="margin: 20px 0; font-size: 2.5rem; color: #000; font-weight: bold;">
              Facturación No Disponible
            </h1>
          </div>
          <div style="margin-bottom: 20px; font-size: 1.2rem; line-height: 1.8; text-align: center; color: #333;">
            <h4>Lamentamos las molestias</h4>
          </div>
          <div style="margin-bottom: 20px; font-size: 1.2rem; line-height: 1.8; text-align: center; color: #333;">
            <h4>Las páginas de facturación de Japac no están disponibles por el momento debido a que estamos
              realizando acciones de mantenimiento para brindarte un mejor servicio</h4>
            <h4>Para cualquier duda o aclaración, favor de llamar al <strong>Aquatel 073</strong> o al teléfono 
              <strong>667 759 6100</strong>.</h4>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <img class="logo-aquatel" src="/assets/images/aquatel.svg" alt="Aquatel" />

  </div>
</body>

